import React, { useEffect, useState } from "react";
import Logo from '../../src/images/logo.svg';
import '../../src/css/style.css';
const Header = () => {
    return(
            
            <div className="d-flex justify-content-center mb-20 header">
                <img className="logo-text" src={Logo} alt="Logo" />
              </div>
    )
}
export default Header;