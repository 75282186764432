import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Popup from '../src/emailPopup/index';
import Header from '../src/header/index';

// Lazy load the Invoice component
const Invoice = lazy(() => import('../src/invoice/index'));

// Lazy load the ViewInvoice component
const ViewInvoice = lazy(() => import('../src/invoice/viewInvoice'));

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* Home route */}
          <Route index element={<Invoice />} />

          {/* Route with parameters */}
          <Route path="/Invoice/:Project_Id/:Month/:Year/:ID" element={<Invoice />} />

          {/* Other routes */}
          <Route path="/Popup" element={<Popup />} />
          <Route path="/ViewInvoice" element={<ViewInvoice />} />
          <Route path="/Header" element={<Header />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
