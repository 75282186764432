import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import '../../src/style.css';

const Popup = () => {
	const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [showOTPInput, setShowOTPInput] = useState(false);
    const [otp, setOTP] = useState("");

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmitEmail = () => {
        // Handle email submission logic here
        // For now, just log the email and show OTP input
        console.log("Submitted email:", email);
        setShowOTPInput(true);
    };

    const handleOTPChange = (event) => {
        setOTP(event.target.value);
    };

    const handleSubmitOTP = () => {
        // Handle OTP submission logic here
        // For now, just log the OTP
        console.log("Submitted OTP:", otp);
        navigate('/Invoice')
    };

    return (
        <div className="popup-container">
            <div className="popup">
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                />
                <button onClick={handleSubmitEmail}>Submit Email</button>

                {showOTPInput && (
                    <>
                        <h2>Enter OTP</h2>
                        <input
                            type="text"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={handleOTPChange}
                        />
                        <button onClick={handleSubmitOTP}>Submit OTP</button>
                    </>
                )}
            </div>
        </div>
    );
};

export default Popup;
